<template>
  <div class="card" style="width: 100%;border-color: #4d5a70 !important;">
    <div
      class="card-header"
      :style="
        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
      "
    >
      <span>{{ $t("label.Movements") }}</span>
    </div>
    <div class="card-body">
      <CRow style="overflow-x: auto;">
        <CCol sm="12" class="center-all" style="min-width: 1177px;">
          <div class="movs-container" style="width: 16%;">
            <div class="movs-title">
              <div class="movs-highlight bg-planned">
                <CIcon name="cil-description" class="mr-2"/>
                <span>{{$t('label.planned')}}</span>
              </div>
            </div>
            <div class="movs-title mt-2">
              <div class="movs-subtitle text-mov-discharge">
                {{ $t('label.DISCHARGE') }}
              </div>
              <div class="movs-subtitle text-mov-load">
                {{ $t('label.LOAD') }}
              </div>
            </div>
            <div class="graph">
              <div class="graph-full" v-if="bandera && getTotalPlannedExecutedMovs.PlannedDischarge > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('planned', 'discharge')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('PlannedDischarge')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
              <div class="graph-empty" v-if="bandera && getTotalPlannedExecutedMovs.PlannedLoad > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('planned', 'load')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('PlannedLoad')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
            </div>
          </div>

          <div class="movs-container" style="width: 29%;">
            <div class="movs-title">
              <div class="movs-highlight bg-exec">
                <CIcon name="cil-task" class="mr-2"/>
                <span>{{$t('label.executed')}}</span>
              </div>
            </div>
            <div class="movs-title mt-2">
              <div class="movs-subtitle-executed text-mov-discharge">
                {{ $t('label.DISCHARGE') }}
              </div>
              <div class="movs-subtitle-executed text-mov-load">
                {{ $t('label.LOAD') }}
              </div>
              <div class="movs-subtitle-executed text-mov-restow">
                {{ $t('label.MOVILIZADO') }}
              </div>
              <div class="movs-subtitle-executed text-mov-canceled">
                {{ $t('label.CANCELADO') }}
              </div>
            </div>
            <div class="graph">
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.ExecutedDischarge > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('executed', 'discharge')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('ExecutedDischarge')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.ExecutedLoad > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('executed', 'load')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('ExecutedLoad')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.ExecutedRestow > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('executed', 'restow')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('ExecutedRestow')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.ExecutedCanceled > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('executed', 'canceled')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('ExecutedCanceled')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
            </div>
          </div>

          <div class="movs-container" style="width: 29%;">
            <div class="movs-title">
              <div class="movs-highlight bg-today">
                <CIcon name="cil-calendar" class="mr-2"/>
                <span>{{$t('label.today')}}</span>
              </div>
            </div>
            <div class="movs-title mt-2">
              <div class="movs-subtitle-executed text-mov-discharge">
                {{ $t('label.DISCHARGE') }}
              </div>
              <div class="movs-subtitle-executed text-mov-load">
                {{ $t('label.LOAD') }}
              </div>
              <div class="movs-subtitle-executed text-mov-restow">
                {{ $t('label.MOVILIZADO') }}
              </div>
              <div class="movs-subtitle-executed text-mov-canceled">
                {{ $t('label.CANCELADO') }}
              </div>
            </div>
            <div class="graph">
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.TodayDischarge > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('today', 'discharge')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('TodayDischarge')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.TodayLoad > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('today', 'load')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('TodayLoad')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.TodayRestow > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('executed', 'restow')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('TodayRestow')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
              <div class="graph-executed" v-if="bandera && getTotalPlannedExecutedMovs.TodayCanceled > 0">
                <SimpleDonut
                  Height="110"
                  Width="80"
                  :isDonut="true"
                  :series="getDataByType('executed', 'canceled')"
                  :Colors="Colors"
                  :Categories="labels"
                  :Tooltip="Tooltip('TodayCanceled')"
                  :ShowTotal="true"
                  :dataLabels="false"
                  :FormatterValue="FormatterValue"
                  :FormatterTotal="FormatterTotal"
                  operationalSituation
                ></SimpleDonut>
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" class="mb-1" style="min-width: 1177px;">
          <div class="movs-info mb-1" v-for="PlannedExecutedMovs in PlannedExecutedMovsJson" :key="PlannedExecutedMovs.IdX">
            <div class="movs-labels">
              <span class="movs-icon mr-1" :style="`background:${PlannedExecutedMovs.Color}`"></span>
              <span>{{ getPackagingName(PlannedExecutedMovs) }}</span>
            </div>
            <div class="movs-container movs-direction" style="width: 16%;">
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityPlanningDischarge) }}
              </div>
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityPlanningLoad) }}
              </div>
            </div>
            <div class="movs-container movs-direction" style="width: 29%;">
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityExecutedDischarge) }}
              </div>
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityExecutedLoad) }}
              </div>
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityExecutedRestow) }}
              </div>
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityExecutedCancel) }}
              </div>
            </div>
            <div class="movs-container movs-direction" style="width: 29%;">
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityDischargeToday) }}
              </div>
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityLoadToday) }}
              </div>
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityRestowToday) }}
              </div>
              <div class="movs-value text-center">
                {{ FormatNumber(PlannedExecutedMovs.QuantityCancelToday) }}
              </div>
            </div>
            <div class="movs-percent">
              <CProgress class="movs-progress mr-2">
                <CProgressBar color="descarga-operativa" :value="getTotal(PlannedExecutedMovs)">
                </CProgressBar>
              </CProgress>
              <span>{{ `${getTotal(PlannedExecutedMovs)}%` }}</span>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import SimpleDonut from "../../components/simpleDonut";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

//Data
function data() {
  return {
  }
}

// Methods
function getDataByType(mov, type) {
  switch (mov) {
    case 'planned':
      if(type == 'discharge') {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityPlanningDischarge);
      } else {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityPlanningLoad);
      }
    case 'executed':
      if(type == 'discharge') {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityExecutedDischarge);
      } else if (type == 'load') {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityExecutedLoad);
      } else if (type == 'restow') {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityExecutedRestow);
      }else {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityExecutedCancel);
      }
    case 'today':
      if(type == 'discharge') {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityExecutedLoad);
      } else if (type == 'load') {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityLoadToday);
      } else if (type == 'restow') {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityRestowToday);
      }else {
        return this.PlannedExecutedMovsJson.map(item => item.QuantityCancelToday);
      }
    default:
      return [0, 0, 0, 0, 0];
  }
}

function getPackagingName(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item['PackagingName'+_lang] ?? '';
}

function calcPercent(Value, Total) {
  if((!Value || !Total) || (Value == 0 || Total == 0)) {
    return 0;
  } else{
    let percent = (Value/Total) * 100;
    return Math.round(percent * 1e2) / 1e2;
  }
}

function Tooltip(Parameter) {
  let Total = this.getTotalPlannedExecutedMovs[Parameter] ?? 0; 
  return {
    y: {
      formatter: (Value) => {
        return `${this.calcPercent(Value, Total)}%`;
      }
    }
  }
}

function getTotal(item) {
  let Total = (item?.QuantityPlanningDischarge ?? 0) + (item?.QuantityPlanningLoad ?? 0);
  let Executed = (item?.QuantityExecutedDischarge ?? 0) + (item?.QuantityExecutedLoad ?? 0);
  return this.calcPercent(Executed, Total);
}

function FormatNumber(Number) {
  if (Number) {
    return formatMilDecimal(Number);
  } else {
    return 0;
  }
}

//computed
function bandera(){
  return this.$store.state.situacionOperativaActual.tabsOperationes === 1 && this.$store.state.situacionOperativaActual.carruselTab === 3; 
}

function labels() {
  return this.PlannedExecutedMovsJson.map(item => this.getPackagingName(item));
}

function Colors() {
  return this.PlannedExecutedMovsJson.map(item => item.Color);
}

function FormatterValue() {
  return {
    formatter: (Value) => {
      return this.FormatNumber(Value);
    }
  }
}

function FormatterTotal() {
  return {
    formatter: (W) => {
      return this.FormatNumber(W.globals.seriesTotals.reduce((a, b) => a + b, 0));
    }
  }
}

function getTotalPlannedExecutedMovs() {
  let Total = {
    PlannedDischarge: 0,
    PlannedLoad: 0,
    ExecutedDischarge: 0,
    ExecutedLoad: 0,
    ExecutedRestow: 0,
    ExecutedCanceled: 0,
    TodayDischarge: 0,
    TodayLoad: 0,
    TodayRestow: 0,
    TodayCanceled: 0,
  }
  this.PlannedExecutedMovsJson.forEach(item => {
    Total.PlannedDischarge += item.QuantityPlanningDischarge ?? 0;
    Total.PlannedLoad += item.QuantityPlanningLoad ?? 0;
    Total.ExecutedDischarge += item.QuantityExecutedDischarge ?? 0;
    Total.ExecutedLoad += item.QuantityExecutedLoad ?? 0;
    Total.ExecutedRestow += item.QuantityExecutedRestow ?? 0;
    Total.ExecutedCanceled += item.QuantityExecutedCancel ?? 0;
    Total.TodayDischarge += item.QuantityDischargeToday ?? 0;
    Total.TodayLoad += item.QuantityLoadToday ?? 0;
    Total.TodayRestow += item.QuantityRestowToday ?? 0;
    Total.TodayCanceled += item.QuantityCancelToday ?? 0;
  });
  return Total;
}

export default {
  name: "movement-general-cargo",
  props: {
    PlannedExecutedMovsJson: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    SimpleDonut,
  },
  data,
  methods: {
    getDataByType,
    getPackagingName,
    calcPercent,
    Tooltip,
    getTotal,
    FormatNumber,
  },
  computed: {
    bandera,
    labels,
    Colors,
    FormatterValue,
    FormatterTotal,
    getTotalPlannedExecutedMovs,
  },
};
</script>

<style scoped>
.center-all {
  display: flex;
  justify-content: center;
}
.movs-container {
  display: flex;
  flex-direction: column;

  /* border: 1px solid #000; */
}

.movs-title {
  width: 100%;
  display: flex;
  justify-content: center;
}
.movs-subtitle {
  font-size: 10px;
  width: calc(100% / 2);
  text-align: center;
}
.movs-subtitle-executed {
  font-size: 10px;
  width: calc(100% / 4);
  text-align: center;
}
.movs-highlight {
  width: 97%;
  border-radius: 20px;
  background-color:#000;
  color: #fff;
  text-align: center;
  padding: 4px 0;
}

.graph {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.graph-full,
.graph-empty {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-executed{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.movs-labels,
.movs-percent {
  flex: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.movs-info {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.movs-direction {
  flex-direction: row;
}
.movs-value {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.movs-icon {
  border-radius: 50%;
  padding: 10px 12px;
  color: #fff;
}

.movs-progress {
  width: 64%;
}

.bg-planned {
  background-color: #828c9c;
}
.bg-exec {
  background-color: #ec631b;
}
.bg-today {
  background-color: #1e3a61;
}
.text-mov-discharge {
  color: #EC631B;
  font-weight: bold;
}
.text-mov-load {
  color: #1A3760;
  font-weight: bold;
}
.text-mov-restow {
  color: #007A7B;
  font-weight: bold;
}
.text-mov-canceled {
  color: #e1373f;
  font-weight: bold;
}
</style>